import { LegacyAny } from '@soracom/shared/core';

import { sortBy } from 'lodash-es';
import { PaginationService } from '@user-console/legacy-soracom-api-client';
import { ScRelation } from '../components/paginator';
import { SoracomApiService } from '../components/soracom_api.service';
import {
  LegacyBaseSoracomApiService,
  SoracomApiParams,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import {
  CreateVpgGateIpAddressMapParams,
  JunctionRedirectionConfiguration,
  ExtendedVirtualPrivateGateway as VirtualPrivateGateway,
  VpgGatePeer,
  VpgIpAddressMap,
  VpgPlacementRegion,
  VpgRoutingFilter,
  VpgType,
} from '@soracom/shared/vpg';
import { JunctionInspectionConfiguration } from '@soracom/shared/soracom-api-typescript-client';

export interface OpenVpgGateParams {
  privacySeparatorEnabled?: boolean;
  vxlanId?: number;
}

export interface CreateVpgGatePeerParams {
  outerIpAddress: string;
  innerIpAddress: string;
}

export interface CreateVpgVpcPeeringConnectionParams {
  peerOwnerId: string;
  peerVpcId: string;
  peerRegion: string;
  destinationCidrBlock: string;
}

export interface CreateVpgParams {
  /** Type is required for submitting, but not while editing. */
  type?: VpgType;
  useInternetGateway: boolean;
  offsetId?: string; // optional parameter for 'Type-X'
  vpcId?: string; // optional parameter for 'Type-X'
  placement?: { region: VpgPlacementRegion };
}

export class VirtualPrivateGatewaysService implements TaggableService {
  static $inject: InjectList = ['$log', '$q', 'BaseSoracomApiService', 'SoracomApi', 'PaginationService'];

  resourcePath = 'virtual_private_gateways';

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    private baseSoracomApiService: LegacyBaseSoracomApiService,
    private soracomApi: SoracomApiService,
    private paginationService: PaginationService,
  ) {}

  list(sortByName = true): Promise<ScRelation<VirtualPrivateGateway>> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/virtual_private_gateways',
      query: { limit: 1000 },
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => {
      let data: VirtualPrivateGateway[] = res.data.map((r: any) => new VirtualPrivateGateway(r));
      if (sortByName) {
        data = sortBy(data, (vpg) => (vpg.name ? vpg.name.toLowerCase() : ''));
      }
      const links = this.paginationService.getPaginationLinks(res.headers.link);
      return { data, links };
    });
  }

  get(id: string): Promise<VirtualPrivateGateway> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/virtual_private_gateways/' + id,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return new VirtualPrivateGateway(res.data);
    });
  }

  create(vpg: CreateVpgParams): Promise<VirtualPrivateGateway> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways',
      contentType: 'application/json',
      body: vpg,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return new VirtualPrivateGateway(res.data);
    });
  }

  async terminate(id: string): Promise<void> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + id + '/terminate',
      contentType: 'application/json',
      body: {},
    };
    await this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  createVpcPeeringConnection(vpgId: string, vpcPeeringConnection: CreateVpgVpcPeeringConnectionParams): Promise<any> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + vpgId + '/vpc_peering_connections',
      contentType: 'application/json',
      body: vpcPeeringConnection,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  deleteVpcPeeringConnection(vpgId: string, pcxId: string): Promise<any> {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: '/v1/virtual_private_gateways/' + vpgId + '/vpc_peering_connections/' + pcxId,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  updateTags(id: string, tags: TagParams[]): Promise<unknown> {
    const apiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  deleteTag(id: string, tagName: string): Promise<unknown> {
    const apiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  openGate(vpgId: string, gateOptions?: OpenVpgGateParams): Promise<VirtualPrivateGateway> {
    if (!gateOptions) {
      gateOptions = {};
    }

    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + vpgId + '/gate/open',
      contentType: 'application/json',
      body: gateOptions,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return new VirtualPrivateGateway(res.data);
    });
  }

  closeGate(vpgId: string): Promise<VirtualPrivateGateway> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + vpgId + '/gate/close',
      contentType: 'application/json',
      body: {},
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return new VirtualPrivateGateway(res.data);
    });
  }

  setVxlanId(vpgId: string, vxlanId: number): Promise<any> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + vpgId + '/gate/set_vxlan_id',
      contentType: 'application/json',
      body: { vxlanId: Number(vxlanId) },
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  changePrivacySeparator(vpgId: string, privacySeparatorEnabled: boolean): Promise<any> {
    const command = privacySeparatorEnabled ? 'enable_privacy_separator' : 'disable_privacy_separator';
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + vpgId + '/gate/' + command,
      contentType: 'application/json',
      body: { privacySeparatorEnabled },
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  listGatePeers(vpgId: string): Promise<VpgGatePeer[]> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/virtual_private_gateways/' + vpgId + '/gate/peers',
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => res.data);
  }

  addGatePeer(vpgId: string, gatePeer: CreateVpgGatePeerParams): Promise<VpgGatePeer> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + vpgId + '/gate/peers',
      contentType: 'application/json',
      body: gatePeer,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => res.data);
  }

  deleteGatePeer(vpgId: string, gatePeer: VpgGatePeer): Promise<any> {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: '/v1/virtual_private_gateways/' + vpgId + '/gate/peers/' + gatePeer.outerIpAddress,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  listIpAddressMap(vpgId: string): Promise<VpgIpAddressMap[]> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/virtual_private_gateways/' + vpgId + '/ip_address_map',
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => res.data);
  }

  putIpAddressMap(vpgId: string, entry: CreateVpgGateIpAddressMapParams): Promise<VpgIpAddressMap> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/virtual_private_gateways/' + vpgId + '/ip_address_map',
      contentType: 'application/json',
      body: entry,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams).then((res: LegacyAny) => res.data);
  }

  deleteIpAddressMap(vpgId: string, entry: VpgIpAddressMap): Promise<any> {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: '/v1/virtual_private_gateways/' + vpgId + '/ip_address_map/' + entry.key,
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  addPermission(id: string, operatorId: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/add_permission`,
      contentType: 'application/json',
      body: { operatorId },
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  revokePermission(id: string, operatorId: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/revoke_permission`,
      contentType: 'application/json',
      body: { operatorId },
    };
    return this.baseSoracomApiService.callApiWithToken(apiParams);
  }

  setRoutingFilter(id: string, entries: VpgRoutingFilter[]): Promise<VirtualPrivateGateway> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/set_routing_filter`,
      contentType: 'application/json',
      body: entries,
    };
    return this.baseSoracomApiService
      .callApiWithToken(apiParams)
      .then((res: LegacyAny) => new VirtualPrivateGateway(res.data));
  }

  setInspectionConfiguration(
    id: string,
    inspectionConfig: JunctionInspectionConfiguration,
  ): Promise<VirtualPrivateGateway> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/junction/set_inspection`,
      contentType: 'application/json',
      body: inspectionConfig,
    };
    return this.baseSoracomApiService
      .callApiWithToken(apiParams)
      .then((res: LegacyAny) => new VirtualPrivateGateway(res.data));
  }

  unsetInspectionConfiguration(id: string): Promise<VirtualPrivateGateway> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/junction/unset_inspection`,
      contentType: 'application/json',
      body: {},
    };
    return this.baseSoracomApiService
      .callApiWithToken(apiParams)
      .then((res: LegacyAny) => new VirtualPrivateGateway(res.data));
  }

  setRedirectionConfiguration(
    id: string,
    redirectionConfig: JunctionRedirectionConfiguration,
  ): Promise<VirtualPrivateGateway> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/junction/set_redirection`,
      contentType: 'application/json',
      body: redirectionConfig,
    };
    return this.baseSoracomApiService
      .callApiWithToken(apiParams)
      .then((res: LegacyAny) => new VirtualPrivateGateway(res.data));
  }

  unsetRedirectionConfiguration(id: string): Promise<VirtualPrivateGateway> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/junction/unset_redirection`,
      contentType: 'application/json',
      body: {},
    };
    return this.baseSoracomApiService
      .callApiWithToken(apiParams)
      .then((res: LegacyAny) => new VirtualPrivateGateway(res.data));
  }
}
