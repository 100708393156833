import { LegacyAny } from '@soracom/shared/core';

import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { StringTransformationCache } from '@soracom/shared/core';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { BatchUpdateStatus, SubscriberBatchUpdater } from '../../shared/SubscriberBatchUpdater';
import { UiRichTip } from '@soracom/shared-ng/soracom-ui-legacy';

const kCachedUiPopover = 'kCachedUiPopover';

@Component({
  selector: 'app-subscriber-batch-change',
  templateUrl: './subscriber-batch-change.component.html',
  styleUrls: ['./subscriber-batch-change.component.scss'],
})
/**
 * A simple static component that displays a list of Subscriber objects, and information about the batch change that is/will be applied to them. Used as a child component before bulk-changing SIM properties (e.g. to show the pre-change state). It has also been extended to be able to display batch change progress and result.
 */
export class SubscriberBatchChangeComponent extends AbstractController implements OnInit {
  constructor(public logger: Logger = LoggerService.shared(), private sanitizer: DomSanitizer) {
    super(logger);
  }

  // @ts-expect-error (legacy code incremental fix)
  @Input() updater: SubscriberBatchUpdater;
  @Input() showGroup? = true;
  @Input() showBundle? = false;
  @Input() showAlertManager? = true;
  @Input() i18nPrefix? = 'SubscriberBatchChangeComponent';
  // @ts-expect-error (legacy code incremental fix)
  @ContentChild('succeeded', { static: false }) succeededTemplateRef: TemplateRef<any>;
  // @ts-expect-error (legacy code incremental fix)
  @ContentChild('failed', { static: false }) failedTemplateRef: TemplateRef<any>;

  ngOnInit() {
    super.ngOnInit();
    this.debug('ngOnInit()', this);
  }

  get changeColumnHeading() {
    return this.updater && this.updater.changeColumnHeading;
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface) {
    return this.updater && this.updater.beforeDescription(subscriber);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface) {
    return this.updater && this.updater.afterDescription(subscriber);
  }

  successDescription(subscriber: ExtendedSubscriberInterface) {
    return this.updater && this.updater.successDescription(subscriber);
  }

  get subscribers(): ExtendedSubscriberInterface[] {
    return this.updater && this.updater.subscribers;
  }

  /**
   * returns the update's statuses array, if it exists
   */
  get statuses(): any[] | undefined {
    return this.updater && this.updater.statuses;
  }

  groupName(groupId: string) {
    return this.updater && this.updater.getGroupName(groupId);
  }

  shouldShowUpdatingIndicator(statusIndex: number) {
    return this.statuses && this.statuses[statusIndex] === BatchUpdateStatus.updating;
  }

  private imsiCache = new StringTransformationCache((imsi) => {
    if (!imsi || !imsi.length) {
      return '';
    }
    const html =
      "<span style='margin-right: 0.5em;'>" +
      imsi.substring(0, 5) +
      "</span><wbr><span style='margin-right: 0.5em;'>" +
      imsi.substring(5, 10) +
      "</span><wbr><span style='margin-right: 0.5em;'>" +
      imsi.substring(10);

    return this.sanitizer.bypassSecurityTrustHtml(html);
  });

  makeImsiWithWbr(imsi: LegacyAny) {
    return this.imsiCache.get(imsi);
  }

  /**
   * Returns an update status, or
   */
  statusAt(index: number): BatchUpdateStatus | undefined {
    const status = this.statuses && this.statuses[index];
    return status ? status : undefined;
  }

  waitingAt(index: number) {
    return this.statusAt(index) === BatchUpdateStatus.waiting;
  }

  updatingAt(index: number) {
    return this.statusAt(index) === BatchUpdateStatus.updating;
  }

  succeededAt(index: number) {
    return this.statusAt(index) === BatchUpdateStatus.succeeded;
  }

  failedAt(index: number) {
    return this.statusAt(index) === BatchUpdateStatus.failed;
  }

  errorInfoPopoverAt(index: number) {
    // console.time("TIME errorInfoPopoverAt()");
    const result = this.updater && this.updater.results[index];

    const cachedInstance = result && result[kCachedUiPopover];
    if (cachedInstance) {
      // console.timeEnd("TIME errorInfoPopoverAt()");
      return cachedInstance;
    }

    const alert = new Alert(result, 'danger');

    const richtip = new UiRichTip().configure((r) => {
      r.label = 'Error';
      r.details = alert.textContent;
      r.iconStyle = 'icon-info';
    });
    result[kCachedUiPopover] = richtip;
    // console.timeEnd("TIME errorInfoPopoverAt()");
    return richtip;

    /*
      REGARDING THAT WEIRD CACHING ABOVE:
      Mason 2019-10-23

      This method is called from within the Angular change-detection-rendering loop. This means it gets called something like 20-30 times when the page is built, so it should be efficient. I initially just constructed and returned a new Alert and a new UiPopover every time the method was called, but I worried about the performance hit a little bit. On my beefy desktop, I tested it and got results like this:

      UiPopover.ts:30 UiPopover.constructor()...1
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.68505859375ms
      UiPopover.ts:30 UiPopover.constructor()...2
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 2.327880859375ms
      UiPopover.ts:30 UiPopover.constructor()...3
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.733154296875ms
      UiPopover.ts:30 UiPopover.constructor()...4
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.557861328125ms
      UiPopover.ts:30 UiPopover.constructor()...5
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.455322265625ms
      UiPopover.ts:30 UiPopover.constructor()...6
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.427978515625ms
      UiPopover.ts:30 UiPopover.constructor()...7
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.552734375ms
      UiPopover.ts:30 UiPopover.constructor()...8
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.470947265625ms
      UiPopover.ts:30 UiPopover.constructor()...9
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.4560546875ms
      UiPopover.ts:30 UiPopover.constructor()...10
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.488037109375ms
      UiPopover.ts:30 UiPopover.constructor()...11
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.546875ms
      UiPopover.ts:30 UiPopover.constructor()...12
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.507080078125ms
      UiPopover.ts:30 UiPopover.constructor()...13
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.618896484375ms
      UiPopover.ts:30 UiPopover.constructor()...14
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.509033203125ms
      UiPopover.ts:30 UiPopover.constructor()...15
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.474365234375ms
      UiPopover.ts:30 UiPopover.constructor()...16
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.44091796875ms

      When I added the caching, I got results like:

      UiPopover.constructor()...0
      UiPopover.ts:30 UiPopover.constructor()...1
      subscriber-batch-change.component.ts:158 TIME errorInfoPopoverAt(): 0.56298828125ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.0048828125ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.006103515625ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.00390625ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.004150390625ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.00390625ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.003662109375ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.003662109375ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.005126953125ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.004150390625ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.005126953125ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.004150390625ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.00830078125ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.006103515625ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.003173828125ms
      subscriber-batch-change.component.ts:148 TIME errorInfoPopoverAt(): 0.003173828125ms

      So... that is like... 10ms with no caching, 0.6ms with caching.

      It's hard to decide how important that 9ms savings is, but I think it is best to be efficient in any method that is called from the Angular change detection loop? 🤷🏻‍

      (The caching mechanism here is a kludge, but I think it is safe.)
    */
  }
}
