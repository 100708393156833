import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DsNoticesComponent, SessionStatusTagComponent } from '@soracom/shared-ng/ui-sds';
import {
  SimSessionCountryInfoComponent,
  UiImeiComponent,
  UiSoracomModule
} from '@soracom/shared-ng/ui-soracom';
import { UiSimUpdateHistoryComponent } from '@soracom/ui-sim-details';
import { TagsModule } from '../../../../../libs/shared-ng/ui-soracom/src/lib/tags';
import { MapModule } from '../map/map.module';
import { PrefixedEventHandlersModule } from '../prefixed_event_handlers/prefixed-event-handlers.module';
import { SimPacketCaptureSessionsModule } from '../sim-packet-capture-sessions/sim-packet-capture-sessions.module';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomGroupsModule } from '../soracom-groups/soracom-groups.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { SubscribersModule } from '../subscribers/subscribers.module';
import { HistoricalChartsComponent, TrafficChartsModule } from '@user-console/chart';
import { SimDescriptionComponent } from './sim-description/sim-description.component';
import { SimDetailsService } from './sim-details.service';
import { SimLocalInfoComponent } from './sim-local-info/sim-local-info.component';
import { SimSessionInfoComponent } from './sim-session-info/sim-session-info.component';
import { SimSessionLogsComponent } from './sim-session-logs/sim-session-logs.component';
import { SimTagsEditorComponent } from './sim-tags-editor/sim-tags-editor.component';

import '@soracom/shared/sim-status-info';
import { FixedRateSimDetailsComponent } from 'libs/apps/user-console/fixed-rate-plan/src';

@NgModule({
  declarations: [
    SimSessionInfoComponent,
    SimSessionLogsComponent,
    SimLocalInfoComponent,
    SimTagsEditorComponent,
    SimDescriptionComponent,
  ],
  exports: [
    
    SimSessionInfoComponent,
    SimSessionLogsComponent,
    SimLocalInfoComponent,
    SimTagsEditorComponent,
    SimDescriptionComponent,
    FixedRateSimDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
    HistoricalChartsComponent,
    MapModule,
    PrefixedEventHandlersModule,
    SubscribersModule,
    SoracomCommonModule,
    SoracomUiModule,
    SoracomGroupsModule,
    TrafficChartsModule,
    SimPacketCaptureSessionsModule,
    UiSoracomModule,
    SessionStatusTagComponent,
    SimSessionCountryInfoComponent,
    TagsModule,
    UiImeiComponent,
    UiSimUpdateHistoryComponent,
    FixedRateSimDetailsComponent,
    DsNoticesComponent,
  ],
  providers: [SimDetailsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SimDetailsModule {
}
