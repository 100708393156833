import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { CellInfo } from '@soracom/shared/soracom-platform';
import { formatDateTime } from '@soracom/shared/util-common';
import { useResolveApiErrorReason } from '@soracom/shared-ng/util-common';
import { DsNotice, DsNoticeAlert } from '@soracom/shared/sds-utils';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { SimDetailsService } from '../sim-details.service';

import '@user-console/ui-open-cellid';

@Component({
  selector: 'app-sim-session-info',
  templateUrl: './sim-session-info.component.html',
})
export class SimSessionInfoComponent {
  private parseApiError = useResolveApiErrorReason();
  simDetailsService = inject(SimDetailsService);
  // @ts-expect-error (legacy code incremental fix)
  subscriber: ExtendedSubscriberInterface;
  // @ts-expect-error (legacy code incremental fix)
  location: { lat: number; long: number }[];
  lowAccuracy = false;
  oldLocation = false;
  // @ts-expect-error (legacy code incremental fix)
  lastSeenTimeTranslatedData: { lastSeenTime: string };
  mapZoom?: number;

  errorNotices: DsNotice[] = [];

  constructor(
    private soracomApi: SoracomApiService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.simDetailsService.subscriber.subscribe((sub) => {
      this.subscriber = sub;
      if (sub) {
        this.setupMap();
      }
    });
  }

  setupMap(): void {
    this.unsetLocation();
    this.oldLocation = false;
    const sessionStatus = this.subscriber.sessionStatus;
    const cell = sessionStatus?.cell;

    if (sessionStatus !== undefined && sessionStatus !== null && cell !== undefined && cell !== null) {
      const failureHandler = () => {
        this.updateMapFromLogs(this.subscriber?.imsi);
      };
      // there is no standardization b/w staging and prod apis, so staging returns radio type in Uppercase and prod gives in lowercase, which breaks staging
      cell.radioType = cell.radioType.toLowerCase();
      this.updateMap(cell, failureHandler);
    } else {
      this.updateMapFromLogs(this.subscriber?.imsi);
    }
    this.cdRef.markForCheck();
  }

  unsetLocation = () => {
    // @ts-expect-error (legacy code incremental fix)
    this.location = undefined;
    this.cdRef.markForCheck();
  };

  /**
   * Attempts to update the map, if possible. Errors may happen, and are ignored, but you can
   * handle them if desired by passing a `failureHandler` (optional). That will
   * be invoked on failure, with the error as its single argument.
   */
  async updateMap(cell: CellInfo, failureHandler?: (res: any) => void) {
    try {
      const response = await this.soracomApi.getCellLocation(cell);
      const data = response.data;
      this.updateLocation(cell, data.lat, data.lon);
    } catch (e) {
      if (failureHandler) {
        failureHandler(e);
      } else {
        this.unsetLocation();
      }
    }
    this.cdRef.markForCheck();
  }

  /** Look for any stored SIM logs and if found update the map using the most recent location */
  async updateMapFromLogs(imsi: string | undefined) {
    if (!imsi) {
      //handle corner case where the subscriber is undefined and so imsi was not properly passed in
      this.unsetLocation();
      return;
    }
    const options = { limit: 1 }; // we only want the most recent log entry

    // TODO: Check why is this only for IMSI and not simId where logs can be for both?
    try {
      let sessionLogs;
      try {
        sessionLogs = await this.soracomApi.getSessionLogsByImsi(imsi, options);
      } catch (e) {
        this.errorNotices = [DsNoticeAlert(this.parseApiError(e))];
        return;
      }

      const logData = sessionLogs?.[0];

      if (!logData || logData?.cell === null) {
        return;
      }

      this.oldLocation = true;
      // @ts-expect-error (legacy code incremental fix)
      this.lastSeenTimeTranslatedData = { lastSeenTime: formatDateTime(logData.time, 'datetime_sec') };

      this.updateMap(logData.cell, this.unsetLocation);
    } catch (e) {
      // This error doesn't seem to happen under normal circumstances, unlike with updateMap() which
      // does have expected errors (e.g. "not found"). So
      this.unsetLocation();
      console.error(e); // just in case, we will know
    }
    this.cdRef.markForCheck();
  }

  updateLocation(cell: CellInfo, lat: number, long: number) {
    if (cell.ci === undefined && cell.eci === undefined) {
      // we don't have cell id
      this.lowAccuracy = true;
    } else {
      this.lowAccuracy = false;
    }
    this.mapZoom = this.lowAccuracy ? 9 : 14;
    this.location = [
      {
        lat,
        long,
      },
    ];
    this.cdRef.markForCheck();
  }
}
