import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SimsService } from '../../../../app/shared/subscribers/sims.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { PacketCaptureSession } from '@soracom/shared/soracom-platform';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiInput } from '@soracom/shared-ng/soracom-ui-legacy';
import { SelectionService } from '../selection.service';
import { SimPacketCaptureSessionsService } from '../sim-packet-capture-sessions.service';

@Component({
  selector: 'sim-start-capturing-session',
  templateUrl: './sim-start-capturing-session.component.html',
})
export class SimStartCapturingSessionComponent implements OnInit {
  selection = inject(SelectionService);

  private simPacketCaptureSessionService = inject(SimPacketCaptureSessionsService);
  private cdRef = inject(ChangeDetectorRef);

  /**
   * Current sim id
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() simId: string;

  // @ts-expect-error (legacy code incremental fix)
  formGroup: FormGroup;
  alertManager = new AlertsManager();

  isRequesting = false;

  prefix = new UiInput();
  duration = new UiInput();
  bottomButtonBar = new UiButtonBar();

  constructor(private simService: SimsService) {
    this.setupControls();
  }

  ngOnInit() {}

  setupControls() {
    this.formGroup = new FormGroup({
      prefix: new FormControl(),
      duration: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d+$/),
        Validators.min(60),
        Validators.max(86400),
      ]),
    });

    this.prefix.labelId = 'simPacketCaptureSessions.requestCaptureModal.label.prefix';
    // @ts-expect-error (legacy code incremental fix)
    this.prefix.formControl = this.formGroup.get('prefix');
    this.duration.labelId = 'simPacketCaptureSessions.requestCaptureModal.label.duration';
    this.duration.type = 'number';
    this.duration.required = true;
    // @ts-expect-error (legacy code incremental fix)
    this.duration.formControl = this.formGroup.get('duration');
    this.duration.validationErrorMessageStringId = 'simPacketCaptureSessions.validation.duration';

    this.bottomButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'simPacketCaptureSessions.requestCaptureModal.button.cancel';
          button.onClick = () => this.hide();
        }),
        UiButton.configure((button) => {
          button.titleId = 'simPacketCaptureSessions.requestCaptureModal.button.start';
          button.onClick = this.requestCaptureSession;
          button.buttonStyle = 'primary';
          button.isDisabled_ƒ = () => !this.formGroup.valid || this.isRequesting;
          button.buttonLoadingState_ƒ = () => (this.isRequesting ? 'loading' : undefined);
        }),
      ];
    });
  }

  hide = (afterSuccess = false) => {
    this.selection.closePanel(afterSuccess);
  };

  requestCaptureSession = async () => {
    this.alertManager.clear();
    this.isRequesting = true;
    if (this.formGroup.valid) {
      try {
        await this.simPacketCaptureSessionService.requestSession(this.simId, this.formGroup.value);
        this.hide(true);
      } catch (e) {
        this.alertManager.add(Alert.fromApiError(e, 'simPacketCaptureSessions.requestCaptureModal.commonApiError'));
      } finally {
        this.isRequesting = false;
        this.cdRef.markForCheck();
      }
    }
  };
}
